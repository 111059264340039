.container {
    width: 260px;
    height: 96px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    transition: 0.2s;
}

.container_open {
    width: 260px;
    height: 96px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: 0.2s;
}

.outerBarTop {
    position: absolute;
    top: 0;
    left: 33%;
    width: 33%;
    height: 1px;
    background-color: black;
}

.outerBarRight {
    position: absolute;
    top: 33%;
    right: 0%;
    width: 1px;
    height: 33%;
    background-color: black;
}

.outerBarBottom {
    position: absolute;
    bottom: 0%;
    right: 33%;
    width: 33%;
    height: 1px;
    background-color: black;
}

.outerBarLeft {
    position: absolute;
    bottom: 33%;
    left: 0%;
    width: 1px;
    height: 33%;
    background-color: black;
}

.outerRim {
    padding: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 4px solid black;
    width: 226px;

}

.innerRim {
    padding: 6px;
    border: 2px solid black;
    font-family: 'Quattrocento', serif;
    font-size: 40px;
    font-weight: bold;
    width: 220px;
}

@media only screen and (max-width: 670px) and (max-height: 375px) {
    
}



