.container {
    background-color: transparent;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 32;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;
}

.containerFullScreen {
    background-color: black;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 32;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;

}

.close {
    color: white;
    position: fixed;
    top: 23px;
    right: 70px;
    z-index: 32;
    width: 32px;
    height: 32px;
}

.arrow {
    color: white;
    transform: scale(1, 1.5)
}

.modal {
    display: grid;
    background-color: black;
    grid-template-columns: 52px auto 52px;
    width: 80vw;
    max-height: 70vh;
    overflow: hidden;
    transition: all 0.3s;

}

.modalFullScreen {
    display: flex;
    align-items: center;
    background-color: black;
    transition: all 0.3s;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

.arrowBox {
    position: relative;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 32;
}

.image {
    display: block;
    object-fit: cover;
    max-width: 90%;
    max-height: 70vh;
    justify-self: center;
    align-self: center;
    filter: grayscale(0%);
    transition: filter 300ms;
    transition: all 0.3s;

}

.imageFullScreen {
    display: block;
    object-fit: cover;
    top: 2%;
    max-width: 96%;
    max-height: 96%;
    position: relative;
    margin: 0 auto;
    transition: all 0.3s;

}

.blackAndWhiteImg {
    display: block;
    object-fit: cover;
    max-width: 90%;
    max-height: 70vh;
    justify-self: center;
    align-self: center;
    filter: grayscale(100%);
    transition: filter 300ms;
}

.fullscreen {
    width: 30px;
    height: 18px;
    position: absolute;
    top: 30px;
    right: 30px;
    z-index: 100;
}



@media (max-width: 430px) {
    .modal {
        width: 100vw;
        max-height: 100vh;
    }
}