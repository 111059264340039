.container {
    position: fixed;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 0 0 20px;
}

.decorator_left {
    display: flex;
    justify-content: center;
    align-items: center;
}


.decorator_left img {

}

.sideMenuBox {
    margin: 0;
    padding: 0;
}

.sideMenuItem {
    font-family: 'Cinzel', cursive;
    font-size: 24px;
    font-weight: bold;
    list-style: none;
    text-align: left;
    line-height: 60px;
    cursor: pointer;
}

.activeLink {
    list-style: none;
    height: 1px;
}

.sideMenu {
    margin: 0;
    padding: 20px;
    width: 100px;
}

@media only screen and (max-width: 820px) and (min-width: 540px) {

    .container {
        height: 220px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .sideMenuBox {
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .sideMenu {
        height: 200px;
    }

    .sideMenuItem {
        font-size: 18px;
        padding: 0 4px;
        z-index: 10000;
        height: 40px;
        max-height: 60px;
        margin: 0;
        grid-row: 1 /2;
    }

    .activeLink {
        height: 0px;
    }
    .decorator_left {
        height: 200px;
        display: flex;
        align-items: center;
    }
    .decorator_left img{
        transform: translate(-20%, 5% );
        height: 200px;
        
    }



}

@media (max-width: 430px) {

    .container {
        width: 100vw;
        max-height: 120px;
        top: none;
        left: 0;
        bottom: 0;
        margin: 0;
        padding: 0;
        flex-direction: column;
        background-color: black;
        z-index: 10000;

    }

    .decorator_left{
        max-height: 30px;
        padding: 0;
    }
    
    

    .sideMenuBox {
        width: 100%;
        max-height: 80px;
        z-index: 10000;

    }
    .sideMenuBox ul {
        display: grid;
        grid-template-columns: 1f 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        width: 90vw;
        max-height: 120px;
        margin: 0 auto 10px;
        padding: 0;
        z-index: 10000;
}

    .sideMenuItem {
        font-size: 16px;
        padding: 0 4px;
        color: white;
        z-index: 10000;
        max-height: 120px;
        margin: 0;
        grid-row: 1 /2;
    }

    .activeLink {
        grid-row: 2/3;
    }

    .sideMenuItem:first-child {
        font-size: 18px;
        padding: 0 4px 0 0 ;
    }
}

@media only screen and (max-height: 430px) and (max-width: 667px) {
    
    .container {
        width: 40%;
        max-height: 80px;
        position: fixed;
        top: 0;
        left:0; 
        z-index: 10000;

    }

    .sideMenuBox {
        width: 100%;
        max-height: 80px;
        z-index: 10000;

    }
    .sideMenuBox ul {
        display: grid;
        grid-template-columns: 1f 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        width: 90%;
        max-height: 70px;
        margin: 0 auto 10px;
        padding: 0;
        z-index: 10000;
}


.sideMenuItem {
    position: relative;
    right: 18px;
    font-size: 16px;
    padding: 0 4px;
    z-index: 10000;
    max-height: 120px;
    margin: 0;
    grid-row: 1 /2;
}

.activeLink {
    grid-row: 2/3;
}


}


