.formContainer{
    text-align: left;
    height: 90%;
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; 
    align-items: center;
    background-color: black;
    border-radius: 10%;
    padding: 40px;
    box-sizing: border-box;
    overflow: scroll;
    animation: formSlide 1s forwards ease-out;

}


.singleEvent {
    display: flex;
    flex-direction: column;
}

.title {
    font-family: 'Cinzel', cursive;
    font-size: 32px;
    font-weight: bold;
    color: white;
}

.buttons {
   
    display: flex;
    justify-content: space-between;
    height: 60px;
}

.button img {
    width: 42px;
    height: 42px;
    margin-left: 12px;
    transform: translateY(25%);
    
}


.button {
    color: white;
    background-color: black;
    border: none;
    width: 200px;
    align-self: flex-end;
    margin: 20px 0 40px;
    font-family: 'Quattrocento', serif;
    font-weight: bold;
    font-size: 20px;
    transform: scale(1, 1);
    transition: 0.3s all;
}

.button:hover {
    transform: scale(1.2, 1.2);
    color: gold;
    transition: 0.3s all;
}

.error {
    color: red;
    font-family:'Quattrocento sans', sans-serif;
    font-size: 12px;
    opacity: 1;
    margin: 0;
    padding: 0;
    transform: translateY(60%);
    animation: errorBlink 2s ease-in infinite;
}

.responseMessage {
    text-align: center;
    width: 100%;
    color: lightgreen;
    font-family: 'Quattrocento', serif;
    font-size: 20px;
    height: 30px;
    margin: 0 auto;
    overflow: hidden;
    animation: messageFade 5s ease-in forwards;
}

.hidden {
    position: absolute;
    bottom: 10px;
    width: 100%;
    height: 30px;
    color: black;
    background-color: transparent;
    overflow:hidden;
    
    margin: 0;
    padding: 0;
}

.events_list {
    overflow-x: visible;
    overflow-y: scroll;
    width: 70%;
    height: 80%;
    margin: 20px 0;
    padding: 20px;
}



.events_list::-webkit-scrollbar {
    width: 10px;
}
.events_list::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 20%;
  }

.events_list::-webkit-scrollbar-thumb{
    width: 10px;
    height: 10px;
    background-color: grey;
    border-radius: 20%;
}

@keyframes errorBlink {
    0% { opacity: 1 }
    100% { opacity: 0 }
}

@keyframes messageFade {
    0% { opacity: 1 }
    100% { opacity: 0 }
}

@keyframes formSlide {
    0% {transform: translateX(200%);}
    100% {transform: translateX(0%);}
}

