
.events_content {
    font-family: 'Quattrocento';
    color: white;
    text-align: left;
    line-height: 30px;
    
}

.link a {
    color: gold;
}

.link a:hover{
    color: green;
}

.events_content a {
    color: gold;
}

.datum , .untertitel, .ort, .link {
font-size: 18px;
}

.datum {
    font-style: italic;
    margin: 22px 0 0;
}

.titel {
font-size: 20px;
font-weight: bold;
margin: 0;
}


.untertitel {
    margin: 0;
}

.ort {
    margin: 0;
}

.link {
    margin: 0 0 20px;
}

.titel a {
    color: gold;
}