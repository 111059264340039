.upperLine {
    position: relative;
    display: block;
    background-color: black;
    border: none;
    width: 20%;
    height: 4px;
    min-width: 20px;
    margin: 0 0 4px;
    min-height: 4px;
    
    transform: translateX(-20%);
z-index: 1000000;

}

.lowerLine {
    position: relative;
    display: block;
    background-color: black;
    border: none;
    width: 20%;
    height: 2px;
    min-width: 20px;
    min-height: 2px;
    margin: 4px 0 0;
    transform: translateX(20%);
    z-index: 1000000;


}