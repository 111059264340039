.events_list {
    overflow-x: hidden;
    overflow-y: scroll;
    width: 90%;
    min-height: 70%;
    margin: 0;
}

.events_title {
    font-family: 'Quattrocento', serif;
    font-size: 32px;
    font-weight: bold;
    margin: 40px 0 40px;
    color: white;
}


.events_list::-webkit-scrollbar {
    width: 10px;
}
.events_list::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 20%;
  }

.events_list::-webkit-scrollbar-thumb{
    width: 10px;
    height: 10px;
    background-color: grey;
    border-radius: 20%;
}


.back {
    color: white;
    display: flex;
    justify-content: space-between;
    width: 132px;
    font-family: 'Quattrocento', serif;
    font-weight: bold;
    margin: 20px auto 0;
    transform: translateY(50%);
    font-size: 24px;
}