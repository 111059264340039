.container {
    margin: 0;
    padding: 0;
    width: 100%
}

.title {
    font-family: 'Quattrocento', serif;
    font-size: 32px;
    font-weight: bold;
    margin-top: 20px;
    color: white;
    margin: 40px 0 40px;
}

.content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 90%;
}

.addressContainer {
    color: white;
    font-family: 'Quattrocento', serif;
    justify-self: right;
    align-self: start;
    padding-top: 10px;
    box-sizing: border-box;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-style: normal;
}

.address {
    padding: 0 0 20px;
}

.addressContainer a {
    color: gold;
    margin: 0 0 20px;
}

.addressContainer img.upperSeparator {
    transform: scale(80%, 90%);
}

.addressContainer img.lowerSeparator {
    transform: scale(-1, -1) scale(80%, 90%);
}

.contactForm {
    justify-self: left;
    text-align: left;
   height: 90%;
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: space-between; 
   align-items: left;

}

.label {
    font-family: 'Cinzel', cursive;
    font-size: 20px;
    color: white;
    text-align: left;
}

.input {
    height: 24px;
    width: 90%;
    box-shadow: inset 0 0 10px #000000;
}



.input_error {
    height: 24px;
    width: 90%;
    box-shadow: inset 0 0 10px #000000;
    background-color: salmon;
    
}

.textarea {
    width: 90%;
    box-shadow: inset 0 0 10px #000000;

}

.textarea_error {
    width: 90%;
    box-shadow: inset 0 0 10px #000000;
    background-color: salmon;
}

.buttons {
   
    display: flex;
    justify-content: space-between;
    height: 60px;
}

.button img {
    width: 42px;
    height: 42px;
    margin-left: 12px;
    transform: translateY(25%);
    
}

.button img.submit {
    transform: rotate(90deg) translateX(25%);
}
.button {
    color: white;
    background-color: black;
    border: none;
    display: flex;
    justify-content: flex-start;
    width: 112px;
    font-family: 'Quattrocento', serif;
    font-weight: bold;
    font-size: 20px;
    transform: scale(1, 1);
    transition: 0.3s all;
}

.button:hover {
    transform: scale(1.2, 1.2);
    color: gold;
    transition: 0.3s all;
}

.error {
    color: red;
    font-family:'Quattrocento sans', sans-serif;
    font-size: 12px;
    opacity: 1;
    margin: 0;
    padding: 0;
    transform: translateY(60%);
    animation: errorBlink 2s ease-in infinite;
}

.formResponseMessage {
    position: absolute;
    bottom: 10px;
    left: 30%;
    color: lightgreen;
    font-family: 'Quattrocento', serif;
    font-size: 20px;
    height: 30px;
    margin: 0 auto;
    overflow: hidden;
    animation: messageFade 5s forwards ease-in;
}

.hidden {
    position: absolute;
    bottom: 10px;
    width: 100%;
    height: 30px;
    color: black;
    background-color: transparent;
    overflow:hidden;
    
    margin: 0;
    padding: 0;
}

@keyframes errorBlink {
    0% { opacity: 1 }
    100% { opacity: 0 }
}

@keyframes messageFade {
    0% { opacity: 1 }
    100% { opacity: 0 }
}

@media (max-width: 430px) {

    .container {
        overflow: scroll;
    }
    .content {
        display: flex;
        flex-direction: column;
        overflow: scroll;
        height: 100%;
        margin: 0;
        padding: 0;
    }

    .addressContainer {
        display: none;
    }

    .contactForm {
        align-items: center;
        margin: 0 0 20px;
        padding: 0;
        height: 100%;
    }

    .buttons {
        flex-direction: column;
        align-items: center;
    }

    .button {
        width: 140px;
        justify-content: space-between;
    }

    .textarea {
        height: 150px;
        margin-bottom: 0;
    }
}

