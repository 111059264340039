.contactForm {
    text-align: left;
   height: 90%;
   width: 70%;
   display: flex;
   flex-direction: column;
   justify-content: space-between; 
   align-items: center;
   background-color: black;
   border-radius: 10%;
   padding: 15% 40px;
   box-sizing: border-box;
   animation: formSlide 1s forwards ease-out;
}


.title {
    font-family: 'Cinzel', cursive;
    font-size: 32px;
    font-weight: bold;
    color: white;
}

.inputField {
    width: 80%;
    max-height: 150px;
}
.label {
    font-family: 'Cinzel', cursive;
    font-size: 20px;
    color: white;
    text-align: left;
    align-self: left;
}

.input {
    height: 24px;
    width: 100%;
    box-shadow: inset 0 0 10px #000000;
}



.input_error {
    height: 24px;
    width: 90%;
    box-shadow: inset 0 0 10px #000000;
    background-color: salmon;
    
}

.textarea {
    width: 100%;
    box-shadow: inset 0 0 10px #000000;

}

.textarea_error {
    width: 90%;
    box-shadow: inset 0 0 10px #000000;
    background-color: salmon;
}

.buttons {
   
    display: flex;
    justify-content: space-between;
    height: 60px;
    width: 60%;
}

.button img {
    width: 42px;
    height: 42px;
    margin-left: 12px;
    transform: translateY(25%);
    
}

.button img.submit {
    transform: rotate(90deg) translateX(25%);
}
.button {
    color: white;
    background-color: black;
    border: none;
    display: flex;
    justify-content: flex-start;
    width: 112px;
    font-family: 'Quattrocento', serif;
    font-weight: bold;
    font-size: 20px;
    transform: scale(1, 1);
    transition: 0.3s all;
}

.button:hover {
    transform: scale(1.2, 1.2);
    color: gold;
    transition: 0.3s all;
}

.error {
    color: red;
    font-family:'Quattrocento sans', sans-serif;
    font-size: 12px;
    opacity: 1;
    margin: 0;
    padding: 0;
    transform: translateY(60%);
    animation: errorBlink 2s ease-in infinite;
}

.formResponseMessage {
    text-align: center;
    width: 100%;
    color: lightgreen;
    font-family: 'Quattrocento', serif;
    font-size: 20px;
    height: 30px;
    margin: 0 auto;
    overflow: hidden;
    animation: messageFade 5s ease-in forwards;
}

.hidden {
    display: none;
}

@keyframes errorBlink {
    0% { opacity: 1 }
    100% { opacity: 0 }
}

@keyframes messageFade {
    0% { opacity: 1 }
    100% { opacity: 0 }
}

@keyframes formSlide {
    0% {transform: translateX(200%);}
    100% {transform: translateX(0%);}
}
