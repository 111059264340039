.container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: black;
}

.title {
    color: white;
    font-family: 'Cinzel', cursive;
    font-size: 40px;
    margin-bottom: 40px;
}

.contactForm {
    justify-self: left;
    text-align: left;
   height: 90%;
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: space-between; 
   align-items: left;

}

.label {
    font-family: 'Cinzel', cursive;
    font-size: 20px;
    color: white;
    text-align: left;
}

.input {
    height: 24px;
    width: 90%;
    box-shadow: inset 0 0 10px #000000;
    margin-bottom: 40px;
}



.input_error {
    height: 24px;
    width: 90%;
    box-shadow: inset 0 0 10px #000000;
    background-color: salmon;
    
}

.textarea {
    width: 90%;
    box-shadow: inset 0 0 10px #000000;

}

.textarea_error {
    width: 90%;
    box-shadow: inset 0 0 10px #000000;
    background-color: salmon;
}

.buttons {
   
    display: flex;
    justify-content: flex-end;
    height: 60px;
}

.button img {
    width: 42px;
    height: 42px;
    margin-left: 12px;
    transform: translateY(25%);
    
}

.button img.submit {
    transform: rotate(90deg) translateX(25%);
}
.button {
    color: white;
    background-color: black;
    border: none;
    display: flex;
    justify-content: flex-start;
    width: 112px;
    font-family: 'Quattrocento', serif;
    font-weight: bold;
    font-size: 20px;
    transform: scale(1, 1);
    transition: 0.3s all;
}

.button:hover {
    transform: scale(1.2, 1.2);
    color: gold;
    transition: 0.3s all;
}

.error {
    color: red;
    font-family:'Quattrocento sans', sans-serif;
    font-size: 12px;
    opacity: 1;
    margin: 0;
    padding: 0;
    transform: translateY(60%);
    animation: errorBlink 2s ease-in infinite;
}

.responseMessage {
    color: white;
    font-family: 'Quattrocento', serif;
    font-size: 20px;
    height: 30px;
    margin: 0 auto;
    overflow: hidden;
}
