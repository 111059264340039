.logo_box {
    
    width: 344px;
    height: 176px;
    background-color: black;
    border-radius: 30px;
    z-index: 10;
    grid-column: 3 / 5;
    grid-row: 3 / 5;
    padding: 34px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 0.5s all ease-in;

}

.logo_box_open {
    position: relative;
    width: 540px;
    max-height: 97vh;
    background-color: black;
    border-radius: 30px;
    z-index: 10;
    grid-column: 3 / 6;
    grid-row: 1 / 7;
    padding: 34px;
    margin: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    transform: translateX(-5%);
    transition: 0.5s all ease-out;

}

.logo {
    width: 300px;
    height: 132px;
    background-color: black;
    border-radius: 30px;
    margin-top: 4px;
    z-index: 11;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.firstName {
    font-family: 'Cinzel Decorative', cursive;
    font-size: 50px;
    color: white;
    align-self: flex-start;
    font-weight: normal;
}

.name {
    font-family: 'Cinzel Decorative', cursive;
    font-size: 62px;
    font-weight: bold;
    text-align: right;
    color: white;
    align-self: flex-end;
}

.upperLine {
    display: block;
    color: white;
    background-color: white;
    width: 100%;
    height: 4px;
    margin: 0 0 2px;

}

.lowerLine {
    display: block;
    background-color: white;
    width: 50%;
    height: 2px;
    margin: 2px 0 0;
}

.close {
    position: fixed;
    top: 20px;
    right: 20px;
    color: #fdfffa;
}

@media (max-width: 430px) {

    .logo_box {
        width: 200px;
        height: 120px;
        grid-column: 3 / 5;
        grid-row: 3 / 5;
    
    }

    .logo {
        width: 160px;
        height: 120px;
    }
    
    .firstName {
        font-size: 28px;
    }
    
    .name {
        font-size: 36px;
    }

    .logo_box_open {
        position: fixed;
        width: 100vw;
        min-height: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: 0;
        transform: translate(0%);
        box-sizing: border-box;
        border-radius: 0%;

    }

    

}

@media only screen and (max-width: 670px) and (max-height: 375px) {

    .logo_box {
        width: 188px;
        height: 120px;
        grid-column: 3 / 6;
        grid-row: 3 / 5;
    
    }

    .logo {
        width: 160px;
        height: 120px;
    }
    
    .firstName {
        font-size: 28px;
    }
    
    .name {
        font-size: 36px;
    }

    .logo_box_open {
        position: fixed;
        width: 100vw;
        min-height: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: 0;
        transform: translate(0%);
        box-sizing: border-box;
        border-radius: 0%;

    }

}

