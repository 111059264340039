/* Existing styles from Lyricist.module.css */
.main {
    background-color: #fff245;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: none;
    -webkit-scrollbar: none;
}

a:hover, article div p a:hover {
    color: rgba(127,181,64,1) !important
}



.main::-webkit-scrollbar {
    display: none
}

.picture {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 24%;
}

.thumbnail {
    max-width: 160px;
    max-height: 160px;
    float: left;
    margin: 0 20px 0 0;
}

.content {
    width: 560px;
    position: absolute;
    right: 40px;
    top: 260px;
    text-align: left;
    overflow-y: scroll;
    scrollbar-width: none;
    -webkit-scrollbar: none;
}

.content::-webkit-scrollbar {
    display: none;
}

.articleSeparator {
    margin-bottom: 40px;
}

.articleTitle {
    font-family: 'Quattrocento', serif;
    font-weight: bold;
    font-size: 40px;
    display: flex;
    margin-left: 40px;
}

.articleTitleCenter {
    font-family: 'Quattrocento', serif;
    font-weight: bold;
    font-size: 40px;
    text-align: center;
    width: 100%;
    margin-bottom: 40px;
}

.werk {
    clear: left;
    padding-top: 10px;
}

.titleShift {
    display: block;
    transform: translate(-30%, 90%);
}

.articleContent {
    font-family: 'Quattrocento sans', sans-serif;
    font-size: 18px;
    text-align: justify;
}


/* Additional styles for expandable content */
.expandableTitle {
    display: flex;
    margin: 20px 0;
}

.expandableTitle h4 {
    font-family: 'Quattrocento', serif;
    font-size: 18px;
    display: flex;
    align-items: center;
    text-decoration: underline;
}

.expandableTitle h4:hover {
    color: rgba(127,181,64,1);
}

div.expandableContent1,
div.expandableContent2,
div.expandableContent3 {
    height: 0px !important;
    overflow: hidden;
    opacity: 0;
    margin-bottom: 0px;
    transition: all ease-in-out 0.3s;
}

div.expandableContent1Expanded,
div.expandableContent3Expanded {
    height: 400px;
    margin-bottom: 560px;
    opacity: 1;
    transition: all ease-in-out 0.3s;
}


div.expandableContent2Expanded
 {
    height: 100px;
    margin-bottom: 60px;
    opacity: 1;
    transition: all ease-in-out 0.3s;
}



img.arrowSmaller90g,
img.arrowSmaller180g {
    height: 30px;
    width: 30px;
    margin-right: 20px;
    transition: all ease-in-out 0.3s;
}

img.arrowSmaller180g {
    transform: rotate(180deg);
}

img.arrowSmaller90g {
    transform: rotate(90deg);
}

/* Media queries for responsive design */
@media only screen and (max-width: 821px) and (min-width: 540px) {

    .content {
        width: 50%;
        top: 160px;
    }

    .articleTitle {
        font-size: 28px;
    }

    .articleSeparator {
        width: 100%;
    }
}

@media only screen and (min-device-width: 834px) and (max-device-width: 1194px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    .content {
        width: 40%;
        top: 160px;
    }

    .articleTitle {
        font-size: 28px;
    }

    .articleSeparator {
        width: 100%;
    }
}

@media (max-width: 430px) {
    .picture {
        position: fixed;
        width: 100vw;
        height: 100vh;
        object-fit: cover;
        opacity: 0.15;
    }

    .content {
        width: 90vw;
        top: 200px;
        left: 0;
        padding: 0 20px;
    }

    .articleTitle {
        font-size: 28px;
    }

    .articleSeparator {
        width: 100%;
    }

    .expandableTitle h4:hover {
        color: rgba(127,181,64,1);
    }

    div.expandableContent1Expanded {
    height: 840px;
    margin-bottom: 560px;
    opacity: 1;
    transition: all ease-in-out 0.3s;
}
}


