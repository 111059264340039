.main {
    background-color: rgb(138, 228, 220);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: none;
}

a:hover {
    color: rgba(208,110,110,1) !important;
}

.main::-webkit-scrollbar {
    display: none;
}

.picture {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 24%;
    transform: rotateY(180deg);
}

.thumbnail {
    max-width: 160px;
    max-height: 160px;
    float: left;
    margin: 0 20px 0 0;
}

.content {
    width: 560px;
    position: absolute;
    right: 40px;
    top: 260px;
    text-align: left;
    overflow-y: scroll;
    scrollbar-width: none;

}

.content::-webkit-scrollbar {
    display: none;
}

.werk {
    clear: left;
    padding-top: 10px;
}

.articleSeparator {
    margin-bottom: 40px;
}
.articleTitle {
    font-family: 'Quattrocento', serif;
    font-weight: bold;
    font-size: 40px;
    display: flex;
    margin-left: 40px;
    min-width: 600px;
}

.articleTitleCenter {
    font-family: 'Quattrocento', serif;
    font-weight: bold;
    font-size: 40px;
    text-align: center;
    width: 100%;
    margin-bottom: 40px;
}

.titleShift {
    display: block;
    transform: translate(-30%, 90%);
}

.articleContent {
    font-family: 'Quattrocento sans', sans-serif;
    font-size: 18px;
    text-align: justify;
}

.media {
    margin: 40px 0;
}

/* Stile für die erweiterbaren Titel und Inhalte */
.expandableTitle {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 20px 0;
}

.expandableTitle h4 {
    font-family: 'Quattrocento', serif;
    font-size: 18px;
    text-decoration: underline;
    margin-left: 10px; /* Adjust as necessary for alignment */
}

.expandableTitle h4:hover {
    color: rgba(208,110,110,1);
}

/* Dynamische Höhen und Sichtbarkeiten für die expandable content Blöcke */
div.expandableContent1,
div.expandableContent2,
div.expandableContent3,
div.expandableContent4,
div.expandableContent5,
div.expandableContent6 {
    max-height: 0;
    margin-bottom: 0;
    overflow: hidden;
    opacity: 0;
    transition: all ease-in-out 0.3s;
}

/* Angepasste Höhen für die expandierten Zustände */
div.expandableContent1Expanded,
div.expandableContent2Expanded,
div.expandableContent3Expanded,
div.expandableContent4Expanded,
div.expandableContent5Expanded,
div.expandableContent6Expanded {
    padding: 12px;
    opacity: 1;
    transition: all ease-in-out 0.3s;
}

div.expandableContent1Expanded {
    max-height: 1200px;
    margin-bottom: 20px;
}

div.expandableContent2Expanded {
    max-height: 720px;
    margin-bottom: 20px;
}

div.expandableContent3Expanded {
    max-height: 300px;
    margin-bottom: 120px;
}

div.expandableContent4Expanded {
    max-height: 120px;
}

div.expandableContent5Expanded {
    max-height: 80px;
}

div.expandableContent6Expanded {
    max-height: 800px;
}

img.arrowSmaller90g,
img.arrowSmaller180g {
    height: 30px;
    width: 30px;
    margin-right: 20px;
    transition: all ease-in-out 0.3s;
}
/* Pfeil-Animation */
img.arrowSmaller90g {
    transform: rotate(0deg); /* This means the arrow is pointing right initially */
}

img.arrowSmaller180g {
    transform: rotate(90deg); /* This means the arrow is pointing down when expanded */
}

@media only screen and (max-width: 1024px) and (min-width: 540px) {
    .content {
        width: 50%;
        top: 160px;
    }

    .articleTitle {
       
        font-size: 28px;
     
    }

    .articleSeparator {
        width: 100%;
    }
}

@media only screen and (min-device-width: 834px) and (max-device-width: 1194px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    .content {
        width: 40%;
        top: 160px;
    }

    .articleTitle {
        font-size: 28px;
    }

    .articleSeparator {
        width: 100%;
    }
}

@media (max-width: 430px) {


    .picture {
        position: fixed;
        width: 100vw;
        height: 100vh;
        object-fit: cover;
        opacity: 0.15;


    }

    .content {
        width: 90vw;
        top: 200px;
        left: 0;
        padding: 0 20px;
        text-align: left;
    }

    .articleTitle {
       
        font-size: 28px;
     
    }

    .articleSeparator {
        width: 100%;
    }

    div.expandableContent1Expanded {
        max-height: 2000px;
        margin-bottom: 20px;
    }

    div.expandableContent2Expanded {
        max-height: 2000px;
        margin-bottom: 20px;
    }

    div.expandableContent5Expanded {
        max-height: 400px;
        margin-bottom: 20px;
    }

}



@media only screen and (max-width: 667px) and (max-height: 430px) {

    .main {
        overflow-x: scroll;
    }
    .content {
        width: 400px;
        top: 160px;
    }
    .picture {
        
        width: 30%;
    }

    .articleTitle {
       
        font-size: 28px;
     
    }

    .articleSeparator {
        width: 100%;
    }

   
}

