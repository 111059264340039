body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: grey;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


h1, h2, h3, h4, h5, h6, hr {
  margin: 0;
  padding: 0;
  text-align: left
}

a {
  text-decoration: none;
  color: black;
}

a:hover {
  color: black;
}


article div p {
  line-height: 22px;
}

iframe {
  margin-bottom: 20px;
  width: 560px;
  height: 315px;
}

@media only screen and (max-width: 430px) {
  article div p {
    text-align: left;
  }

  iframe {
    width: 360px;
    height: 202px;
  }
}
