.banner {
    margin-top: 240px;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.logo_box {
    width: 344px;
    height: 176px;
    background-color: transparent;
    padding: 34px;
    box-sizing: border-box;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.firstName {
    font-family: 'Cinzel Decorative', cursive;
    font-size: 50px;
    color: black;
    align-self: flex-start;
    font-weight: normal;
}

.name {
    font-family: 'Cinzel Decorative', cursive;
    font-size: 62px;
    font-weight: bold;
    text-align: right;
    color: black;
    align-self: flex-end;
}

.upperLine {
    display: block;
    background-color: black;
    width: 100%;
    min-height: 4px;
    border: 0;
    margin: 0 0 2px;
    z-index: 5;

}

.lowerLine {
    display: block;
    background-color: black;
    width: 50%;
    min-height: 2px;
    border: 0;
    margin: 2px 0 0;

}
.navFirstLine, .navSecondLine {
    font-family: 'Quattrocento', serif;
    color: black;
    font-size: 32px;
    width: 60%;
    display: flex;
    justify-content: space-between;
}

.navSecondLine {
    transform: translateX(30%);
}

@media ( max-width: 430px) {

    .banner {
        min-height: 400px;
    }

    .firstName {
        font-size: 28px;
    }
    
.logo_box {
        width: 240px;
        height: 100px;
        
    }
    .name {
        font-size: 32px;
    }

    .navFirstLine, .navSecondLine {
       
        font-size: 22px;
       
    }
}

