.banner {
    position: fixed;
    width: 680px;
    height: 220px;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10;
    padding-top: 24px;
    overflow: visible;
}

.title {
    display: flex;
    flex-direction: row;
}

.firstName {
    font-family: 'Cinzel Decorative', cursive;
    font-size: 62px;
    color: black;
    align-self: flex-start;
    font-weight: normal;
    transform: translateY(-10%);
    margin: 4px;
}

.lastName {
    font-family: 'Cinzel Decorative', cursive;
    font-size: 74px;
    font-weight: bold;
    text-align: right;
    color: black;
    align-self: flex-end;
    margin: 4px;

    transform: translateY(10%);

}

.profession {
    font-family: 'Quattrocento', serif;
    font-size: 40px;
    font-weight: normal;
    transform: translateX(50%);
}

@media only screen and (max-width: 1024px) and (min-width: 540px) and (min-height: 415px) {

    .banner {
        width: 60%;
        height: 120px;
        right: 0;
        z-index: 100000;
    }

    .firstName {
        font-size: 32px;
    }

    .lastName {
        font-size: 36px;
    }
    
}

@media only screen and (max-width: 430px) {

    .banner {
        width: 100%;
        height: 120px;
        left: 0;
    }

    .firstName {
        font-size: 32px;
        color: white;
    }

    .lastName {
        font-size: 36px;
        color: white;
    }

    .profession{
        font-size: 24px;
        color: white;
    }

}

@media only screen and (max-width: 670px) and (max-height: 375px) {

    .banner {
        width: 480px;
        height: 120px;
        right: 0;
        align-items: flex-end;
    }

    .title {
        margin: 0 80px 0 0;
    }

    .firstName {
        font-size: 32px;
    }

    .lastName {
        font-size: 36px;
    }

    .profession {
        font-size: 24px;
        margin: 0 80px 0 0;
    }
    
}