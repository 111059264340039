.container {
    display: grid;
    grid-template-columns: auto auto 172px 172px auto auto;
    grid-template-rows: 66px auto auto auto auto 66px;
    width: 100%;
    height: 100vh;
    position: fixed;
}

.container_open {
    display: grid;
    grid-template-columns: auto auto 172px 172px auto auto;
    grid-template-rows: 66px auto auto auto auto 66px;
    width: 100%;
    height: 100vh;
    position: fixed;
}




.badge_lyricist,
.badge_composer,
.badge_philologist,
.badge_guitarist {
    position: absolute;
    font-family: 'Cinzel', cursive;
    font-size: 30px;
    cursor: pointer;
}

.badge_lyricist {
    top: 40%;
    right: 15%;
    
}

.badge_composer {
    
    top: 40%;
    right: 10%;

}

.badge_philologist{
    top: 40%;
    left: 10%;

}

.badge_guitarist {
    top: 40%;
    left: 10%;

}

.noHoverLyricist,
.noHoverGuitarist,
.noHoverComposer,
.noHoverPhilologist {
    pointer-events: none;
}

.badge_open {
    display: none;
}

.upperleft, .upperright, .lowerleft, .lowerright,
.noHoverLyricist, .noHoverComposer, .noHoverPhilologist, .noHoverGuitarist {
    width: 50vw;
    height: 50vh;
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.upperleft, .noHoverLyricist {
background: linear-gradient(120deg, rgb(250, 245, 178) 0%, rgba(255,242,69,1) 100%);
grid-column: 1/4;
grid-row: 1/4;
z-index: 0;
transition: 0.2s all cubic-bezier(.92,.29,1,.73);
}


.upperleft_open {
    background: linear-gradient(120deg, rgb(250, 245, 178) 0%, rgba(255,242,69,1) 100%);
    grid-column: 1/4;
    grid-row: 1/4;
    z-index: 0;
    overflow: hidden;

    transform: translateX(-50%);
    transition: 0.5s all ease-out;
    }

.upperleft:hover {
   transform: scale(110%) translate(4%, 4%) ;
   z-index: 5;
    transition: 1s ease-out;
}





.upperright, .noHoverPhilologist {
    background: rgb(162,234,228);
    background: linear-gradient(120deg, rgb(138, 228, 220) 0%, rgb(228, 252, 251) 100%);
grid-row: 1/4;
grid-column: 4/7;
z-index: 0;
transition: 0.2s all cubic-bezier(.92,.29,1,.73);
}

.upperright_open {
    background: rgb(162,234,228);
    background: linear-gradient(120deg, rgb(138, 228, 220) 0%, rgb(228, 252, 251) 100%);
grid-row: 1/4;
grid-column: 4/7;
z-index: 0;
transform: translateX(60%);
overflow: hidden;
transition: 0.5s ease-out;
}

.upperright:hover {
    transform: scale(110%) translate(-4%, 4%) ;
    z-index: 5;
     transition: 1s ease-out;
 }


.lowerleft, .noHoverComposer {
    background: rgb(127,181,64);
    background: linear-gradient(60deg, rgba(196,222,167,1) 0%, rgba(127,181,64,1) 100%);
    grid-row: 4/7;
    grid-column: 1/4;
    z-index: 0;


transition: 0.2s all cubic-bezier(.92,.29,1,.73);
}

.lowerleft_open {
    background: rgb(127,181,64);
    background: linear-gradient(60deg, rgba(196,222,167,1) 0%, rgba(127,181,64,1) 100%);
    grid-row: 4/7;
    grid-column: 1/4;
    z-index: 0;
    overflow: hidden;

    transform: translateX(-50%);
transition: 0.5s all ease-out;
}

.lowerleft:hover {
    transform: scale(110%) translate(4%, -4%) ;
    z-index: 5;
     transition: 1s ease-out;
 }

.lowerright, .noHoverGuitarist {
    background: rgb(208,110,110);
    background: linear-gradient(60deg, rgba(208,110,110,1) 0%, rgba(255,211,211,1) 100%);
    grid-column: 4/7;
    grid-row: 4/7;
    z-index: 0;
    transition: 0.2s all cubic-bezier(.92,.29,1,.73);
}

.lowerright_open {
    background: rgb(208,110,110);
    background: linear-gradient(60deg, rgba(208,110,110,1) 0%, rgba(255,211,211,1) 100%);
    grid-column: 4/7;
    grid-row: 4/7;
    z-index: 0;
    overflow: hidden;

    transform: translateX(60%);
    transition: 0.5s all ease-out;
}

.lowerright:hover {
    transform: scale(110%) translate(-4%, -4%) ;
    z-index: 5;
     transition: 1s ease-out;
 }

/* .bild_upperleft,
.bild_upperleft_open,
.bild_upperleft_hover,
.bild_upperright,
.bild_upperright_open,
.bild_upperright_hover,
.bild_lowerleft,
.bild_lowerleft_open,
.bild_lowerleft_hover,
.bild_lowerright,
.bild_lowerright_open,
.bild_lowerright_hover {
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
} */


.bild_upperleft, .bild_lowerleft {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 35%;
    transition: 0.2s;
}

.bild_upperleft_hover, .bild_lowerleft_hover {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 45%;
    transition: 1s;
}

.bild_upperright{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 40%;
    transition: 0.2s
}

.bild_lowerright {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 45%;
    transition: 0.2s
}

.bild_upperright_hover{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 45%;
    transition: 1s;
}

.bild_upperright_open, .bild_lowerright_open {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 45%;
    transition: 0.4s
}

.bild_lowerright_hover {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 50%;
    transition: 1s;
}


.bild_upperleft_open, .bild_lowerleft_open {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 45%;
    transition: 0.4s;
    transform: scale(1.3, 1.3);
}



.upperMenuBar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 540px;
    height: 66px;
    background-color: black;
    grid-column: 2/6;
    grid-row: 1/2;
    margin: 0 auto;
    z-index: 10;
    border-radius: 0 0 30px 30px;
}

.upperMenuBar_open {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 540px;
    height: 66px;
    background-color: black;
    grid-column: 2/6;
    grid-row: 1/2;
    margin: 0 auto;
    z-index: 10;
    border-radius: 0 0 30px 30px;
    transform: translateY(-110%);
    transition: 0.5s all;

}

.upperMenu {
    list-style: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 90%;
    text-align: center;
    padding: 0;
}

.menuItem {
    font-family: 'Quattrocento', serif;
    font-size: 28px;
    color: white;
    cursor: pointer;

}

.pallina {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: white;
}

.lowerMenuBar {
    width: 540px;
    height: 66px;
    background-color: black;
    grid-column: 2/6;
    grid-row: 6/7;
    width: 240px;
    margin: 0 auto;
    z-index: 10;
    border-radius: 30px 30px 0 0;
    display: flex;
    justify-content: space-around;
    align-items: center;

}

.lowerMenuBar_open {
    width: 540px;
    height: 66px;
    background-color: black;
    grid-column: 2/6;
    grid-row: 6/7;
    width: 240px;
    margin: 0 auto;
    z-index: 10;
    border-radius: 30px 30px 0 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    transform: translateY(110%);
    transition: 1s all;

}

.fbLogo {
    position: relative;
    bottom: 2px;
    width: 40px;
    height: 40px;
}



.xLogo {
    position: relative;
    right: 2px;
    max-width: 2rem;
}

@media (max-width: 1024px){
    .badge_lyricist, .badge_composer, .badge_philologist, .badge_guitarist {
        font-size: 24px;
    }
}

@media (max-width: 430px) {
    .container {
      
        grid-template-columns: 12% 11% 27% 27% 11% 12%;
        grid-template-rows: 50px auto auto auto auto 50px;
        column-gap: 0px;
        row-gap: 0;
       
    }

    .container_open {
        display: block;
        width: 100vw;
        height: 100vh;
    }

    .badge_lyricist {
        
        top: 30%;
        right: 30%;
     
        font-size: 12px;
    }

    .badge_composer{
        
        top: 20%;
        right: 20%;
     
        font-size: 12px;
    }
    
    .badge_philologist{
       
        top: 30%;
        left: 10%;
       
        font-size: 12px;
    }

    .badge_guitarist{
       
        top: 20%;
        left: 15%;
       
        font-size: 12px;
    }


    .bild_upperleft, .bild_lowerleft {
       
        width: 70%;
       
    }
    
    .bild_upperleft_hover, .bild_lowerleft_hover {
      
        width: 75%;
     
    }

    .bild_upperright, .bild_lowerright {
       
        width: 75%;
    
    }
    
    .bild_upperright_hover, .bild_lowerright_hover {
       
        width: 80%;
       
    }

    .bild_lowerleft{
        transform: scale(110%);
    }
    .bild_lowerright {
        transform: translateY(-10%) scale(125%);
    }

    .upperMenuBar {
        grid-column: 2/6;
        width: 100%;
        height: 52px;
        
    }



    .menuItem {
       
        font-size: 18px;
    
    }

    

    .lowerMenuBar {
        width: 200px;
        height: 50px;
        background-color: black;
        grid-column: 2/5;
        grid-row: 6/7;

        margin: 0 auto;
        z-index: 10;
        border-radius: 30px 30px 0 0;
        display: flex;
        justify-content: space-around;
        align-items: center;
    
    }

    .fbLogo {
        position: relative;
        bottom: 2px;
        width: 30px;
        height: 30px;
    }
    
    .inLogo {

        width: 30px;
        height: 30px;
    
    }
    
    .twLogo {
        position: relative;
        right: 2px;

        height: 55%;
    }

    .upperleft_open, .lowerleft_open {
        transform: translateX(-100%);
        }

    .upperright_open, .lowerright_open {
       transform: translateX(100%);
            }

}

@media only screen and (max-width: 670px) and (max-height: 375px) {

    .container {
      
        grid-template-columns: 12% 24% 14% 14% 24% 12%;
        grid-template-rows: 50px auto auto auto auto 50px;
        column-gap: 0px;
        row-gap: 0;
       
    }

    .badge_lyricist {
        
        top: 40%;
        right: 30%;
     
        font-size: 12px;
    }

    .badge_composer{
        
        top: 40%;
        right: 20%;
     
        font-size: 12px;
    }
    
    .badge_philologist{
       
        top: 40%;
        left: 10%;
       
        font-size: 12px;
    }

    .badge_guitarist{
       
        top: 40%;
        left: 15%;
       
        font-size: 12px;
    }

    .upperMenuBar {
        grid-column: 2/6;
        width: 60%;
        height: 40px;
        
    }



    .menuItem {
       
        font-size: 18px;
    
    }

    .lowerMenuBar {
        width: 220px;
        height: 50px;
        background-color: black;
        grid-column: 2/6;
        grid-row: 6/7;

        z-index: 10;
        border-radius: 30px 30px 0 0;
        box-sizing: border-box;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }


    .fbLogo {
        position: relative;
        width: 30px;
        height: 30px;
    }
    
    .inLogo {
        position: relative;
        width: 30px;
        height: 30px;
    
    }
    
    .twLogo {
        position: relative;
        right: 2px;

        height: 50%;
    }


}