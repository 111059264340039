.container {
    width: 140px;
    height: 52px;
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: 32px;
    left: 400px;
    z-index: 100001;

}

.inLogo {
    transform: translateX(2px);
    max-width: 4rem;
}

.xLogo {
   max-width: 3rem;
}

@media only screen and (max-width: 1024px) and (max-height: 1366) {

    .container {
        
        left: 220px;
        flex-direction: column;
        justify-content: space-around;
        top: 68px;
    }

    .container img {
        max-width: 30px;
        padding: 10px 0;
    }
}

@media only screen and (min-device-width: 834px) and (max-device-width: 1194px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    .container {
        
        left: 220px;
        flex-direction: column;
        justify-content: space-around;
        top: 68px;
    }

    .container img {
        max-width: 30px;
        padding: 10px 0;
    }
}
@media only screen and (max-width: 820px) {

    .container {
        left: 260px;
        flex-direction: column;
    }

    .container img {
        max-width: 30px;
        padding: 10px 0;
    }
}