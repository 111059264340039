.container {
background-color: white;
width: 100vw;
height: 100vh;
display: flex;
justify-content: space-around;
align-items: center;
}

.linksOpen {
    transform: translateX(5vw);
    transition: transform 1s ease-in-out;
}

.links {
    transform: translateX(21vw);
    transition: transform 1s ease-in-out;

}

.form {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.link {
    color: black;
    background-color: white;
    border: none;
    display: flex;
    justify-content: flex-start;
    width: 300px;
    padding: 20px;
    font-family: 'Quattrocento', serif;
    font-weight: bold;
    font-size: 20px;
    transform: scale(1, 1);
    transition: 0.3s all;
}

.link:hover {
    transform: scale(1.2, 1.2);
    color: salmon;
    transition: 0.3s all;
}