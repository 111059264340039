.container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 44px;
    margin: 20px 0 40px;
}

.text {
    font-family: 'Quattrocento', serif;
    font-size: 24px;
    font-weight: bold;
    margin-right: 20px;
}