.backdrop {
    background-color: black;
    opacity: 0.8;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 31;
    transition: opacity 500ms;
}

.backdropClosing {
    background-color: black;
    opacity: 0;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 31;
    transition: opacity 500ms;
}

