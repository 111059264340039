.impressum_title {
    font-family: 'Quattrocento', serif;
    font-size: 32px;
    font-weight: bold;
    margin-top: 20px;
}

.impressum_content {
    font-family: 'Quattrocento sans', sans-serif;
    font-size: 16px;
    color: white;
    text-align: left;
    margin: 20px 0;
    overflow-y: scroll;

}

.impressum_content::-webkit-scrollbar {
    width: 10px;
}
.impressum_content::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 20%;
  }

.impressum_content::-webkit-scrollbar-thumb{
    width: 10px;
    height: 10px;
    background-color: grey;
    border-radius: 20%;
}

.impressum_content a {
    color: gold;
}